<template>
    <v-card flat outlined>
        <v-card-text style="background-color:#eeeeee;" v-if="companyPay.product_type == 'PRO' && companyPay.pay_status == 'PREUSE'">
            <v-row>
                <v-col class="text-center pt-10" style="font-size:24px; font-weight:600;">무료 서비스 이용기간 입니다</v-col>
            </v-row>
            <v-row>
                <v-col class="text-center" style="font-size:20px; font-weight:600;">무료 서비스 이용기간 종료 후 실제 결제 됩니다</v-col>
            </v-row>
            <v-row>
                <v-col class="text-center" style="font-size:18px;"><b>이용기간</b>: {{companyPay.svc_start_date | dateMin}} ~ {{companyPay.svc_end_date | dateMin}}</v-col>
            </v-row>
            <v-row>
                <v-col class="text-right pt-8" style="font-size:18px;">
                    <b>결제수단</b>: <span v-if="companyPay.credit_card_name">{{companyPay.credit_card_name}} ({{companyPay.credit_card_no}})</span><span v-else>등록된 결제수단이 없습니다</span>

                    <v-btn color="#597195" small dark class="ml-7" @click="changePayMethod" v-if="mainAdminYn == 'Y'">결제수단 변경하기</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text style="background-color:#eeeeee;" v-if="companyPay.product_type == 'PRO' && companyPay.pay_status == 'GRACE_PERIOD'">
            <v-row>
                <v-col class="text-center pt-10" style="font-size:24px; font-weight:600;">이번달 ({{monthNum}}월) 청구요금</v-col>
            </v-row>
            <v-row>
                <v-col class="text-center" style="font-size:24px; font-weight:600;">{{companyPay.next_pay_amount | number}}원</v-col>
            </v-row>
            <v-row>
                <v-col class="text-center" style="font-size:24px; font-weight:600;">결제 지연 상태 입니다. 결제수단을 확인해 주세요</v-col>
            </v-row>
            <v-row>
                <v-col class="text-center" style="font-size:18px;"><b>이용기간</b>: {{companyPay.svc_start_date | dateMin}} ~ {{companyPay.svc_end_date | dateMin}}</v-col>
            </v-row>
            <v-row>
                <v-col class="text-right pt-8" style="font-size:18px;">
                    <b>결제수단</b>: <span v-if="companyPay.credit_card_name">{{companyPay.credit_card_name}} ({{companyPay.credit_card_no}})</span><span v-else>등록된 결제수단이 없습니다</span>

                    <v-btn color="#597195" small dark class="ml-7" @click="changePayMethod" v-if="mainAdminYn == 'Y'">결제수단 변경하기</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text style="background-color:#eeeeee;" v-if="companyPay.product_type == 'PRO' && companyPay.pay_status == 'SUCCESS'">
            <v-row>
                <v-col class="text-center pt-10" style="font-size:24px; font-weight:600;">이번달 ({{monthNum}}월) 청구요금</v-col>
            </v-row>
            <v-row>
                <v-col class="text-center" style="font-size:24px; font-weight:600;">{{companyPay.next_pay_amount | number}}원</v-col>
            </v-row>
            <v-row>
                <v-col class="text-center" style="font-size:18px;"><b>이용기간</b>: {{companyPay.svc_start_date | dateMin}} ~ {{companyPay.svc_end_date | dateMin}}</v-col>
            </v-row>
            <v-row>
                <v-col class="text-right pt-8" style="font-size:18px;">
                    <b>결제수단</b>: <span v-if="companyPay.credit_card_name">{{companyPay.credit_card_name}} ({{companyPay.credit_card_no}})</span><span v-else>등록된 결제수단이 없습니다</span>

                    <v-btn color="#597195" small dark class="ml-7" @click="changePayMethod" v-if="mainAdminYn == 'Y'">결제수단 변경하기</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text style="background-color:#eeeeee;" v-if="companyPay.product_type == 'PRO' && companyPay.pay_status == 'NO_METHOD'">
            <v-row>
                <v-col class="text-center" style="font-size:24px; font-weight:600;">등록된 결제수단이 없습니다</v-col>
            </v-row>
            <v-row>
                <v-col class="text-center" style="font-size:20px; font-weight:600;">결제수단을 등록해 주세요</v-col>
            </v-row>
            <v-row>
                <v-col class="text-center">
                    <v-btn color="#597195" large dark style="font-size:20px;" @click="changePayMethod" v-if="mainAdminYn == 'Y'">결제수단등록</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text style="background-color:#eeeeee;" v-if="companyPay.product_type == 'PRO' && companyPay.pay_status == 'FAIL'">
            <v-row>
                <v-col class="text-center" style="font-size:24px; font-weight:600;">결제가 실패되어 서비스가 중단되었습니다</v-col>
            </v-row>
            <v-row>
                <v-col class="text-center" style="font-size:20px; font-weight:600;">결제수단을 등록해 주세요</v-col>
            </v-row>
            <v-row>
                <v-col class="text-center">
                    <v-btn color="#597195" large dark style="font-size:20px;" @click="changePayMethod" v-if="mainAdminYn == 'Y'">결제수단등록</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text style="background-color:#eeeeee;" v-if="companyPay.product_type == 'PTN'">
            <v-row v-if="companyPay.svc_yn == 'Y'">
                <v-col class="text-center" style="font-size:24px; font-weight:600;">서비스 이용중 입니다</v-col>
            </v-row>
            <v-row v-if="companyPay.svc_yn == 'N'">
                <v-col class="text-center" style="font-size:24px; font-weight:600;">서비스가 중단되었습니다</v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'pay_pay_status',
    components: {
    },
    data: () => ({

    }),
    created () { 
    },
    computed: {
        ...mapGetters({
            companyPay: 'pay/getCompanyPay',
            mainAdminYn: 'login/getMainAdminYn',
        }),
        monthNum () { 
            let d = new Date()
            return d.getMonth()+1
        },
    },  
    methods : { 
        changePayMethod () {
            this.$router.push('/pay/forward_pg')
        }
    }
}
</script>